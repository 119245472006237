var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState, useEffect } from "react";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { AuthContext } from "../../../../components/FirebaseAuth";
import { FirebaseAuth } from "../../../../components/FirebaseAuth/firebase";
import { Link } from "react-router-dom";
import Loader from "../../../../components/Loader";
import AlertPreformatted from "../../../../components/AlertPreformatted";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
var currency = require("../../../../inc/currency.json");
var PaymentList = function () {
    var title = 'Payment History';
    var userData = useContext(AuthContext).userData;
    var setBreadcrumb = useContext(BreadcrumbContext).setBreadcrumb;
    // document snapshots
    var pageSize = 10;
    var _a = useState(null), qs = _a[0], setQs = _a[1];
    var _b = useState([]), rows = _b[0], setRows = _b[1];
    var _c = useState(false), toEnd = _c[0], setToEnd = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState(''), invoiceLoading = _e[0], setInvoiceLoading = _e[1];
    var _f = useState(null), errorMessage = _f[0], setErrorMessage = _f[1];
    var getOutdatedInvoice = function (invoiceId) { return __awaiter(void 0, void 0, void 0, function () {
        var getInvoice, invoice_link_1, updated_rows, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setInvoiceLoading(invoiceId);
                    getInvoice = CloudFunctions.httpsCallable('oncall');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getInvoice({
                            name: "getOutdatedInvoice",
                            data: {
                                accountId: userData.currentAccount.id,
                                invoiceId: invoiceId
                            }
                        })];
                case 2:
                    invoice_link_1 = _a.sent();
                    updated_rows = rows.map(function (r) {
                        if (r.id === invoiceId) {
                            r.outdated = false;
                            r.hostedInvoiceUrl = invoice_link_1.data;
                        }
                        return r;
                    });
                    setRows(updated_rows);
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    setErrorMessage("ERROR: " + e_1);
                    return [3 /*break*/, 4];
                case 4:
                    setInvoiceLoading('');
                    return [2 /*return*/];
            }
        });
    }); };
    var getInvoices = function (accountId, pageSize, lastDoc) {
        setLoading(true);
        var records = [];
        var collectionRef = FirebaseAuth.firestore().collection('accounts').doc(accountId).collection('invoices');
        var query = collectionRef.orderBy('created', 'desc');
        if (lastDoc) {
            query = query.startAfter(lastDoc);
        }
        query = query.limit(pageSize);
        query.get().then(function (documentSnapshots) {
            if (documentSnapshots.empty) {
                setToEnd(true);
            }
            else {
                documentSnapshots.forEach(function (doc) {
                    var regenerated = doc.data().hasOwnProperty('regenerated') ? Math.round(parseInt(doc.data().regenerated) / 1000) : doc.data().created;
                    var dateEnd = new Date(0); // The 0 there is the key, which sets the date to the epoch
                    dateEnd.setUTCSeconds(regenerated);
                    var dateToday = new Date();
                    var DifferenceInTime = dateToday.getTime() - dateEnd.getTime();
                    var DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
                    var outdated = doc.data().hasOwnProperty('regenerated') ? (DifferenceInDays > 13) : (DifferenceInDays > 29);
                    records.push({
                        'id': doc.id,
                        'total': (doc.data().total / 100).toFixed(2),
                        'subTotal': (doc.data().subTotal / 100).toFixed(2),
                        'tax': ((doc.data().tax || 0) / 100).toFixed(2),
                        'amountPaid': (doc.data().amountPaid / 100).toFixed(2),
                        'created': (new Date(doc.data().created * 1000)).toLocaleString(),
                        'outdated': outdated,
                        'hostedInvoiceUrl': doc.data().hostedInvoiceUrl,
                        'currency': doc.data().currency,
                        'status': doc.data().status
                    });
                });
                if (records.length > 0) {
                    setRows(function (rows) { return rows.concat(records); });
                    setQs(documentSnapshots);
                }
            }
            setLoading(false);
        });
    };
    useEffect(function () {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/account/" + userData.currentAccount.id + "/",
                text: userData.currentAccount.name + " (".concat(userData.currentAccount.id, ")"),
                active: false
            },
            {
                to: null,
                text: "Billing",
                active: true
            }
        ]);
        getInvoices(userData.currentAccount.id, pageSize);
    }, [userData, setBreadcrumb]);
    return (_jsx(_Fragment, { children: _jsx("div", { className: "container-fluid", children: _jsxs("div", { className: "animated fadeIn", children: [_jsx("div", { className: "text-right mb-3", children: (userData.currentAccount.role === 'admin') &&
                            _jsxs(_Fragment, { children: [userData.currentAccount.price > 0 &&
                                        _jsx(Link, { to: "/account/" + userData.currentAccount.id + "/billing/payment-method", className: "btn btn-primary mr-2", children: "Update Payment Method" }), _jsx(Link, { to: "/account/" + userData.currentAccount.id + "/billing/plan", className: "btn btn-primary mr-2", children: "Change Subscription Plan" }), _jsx(Link, { to: "/account/" + userData.currentAccount.id + "/billing/delete", className: "btn btn-danger", children: "Delete Account" })] }) }), errorMessage !== null && (_jsx(AlertPreformatted, { type: "warning", message: errorMessage, dismissible: true, onDismiss: function () { return setErrorMessage(null); } })), _jsxs("div", { className: "card", children: [_jsx("div", { className: "card-header", children: title }), _jsxs("div", { className: "card-body", children: [rows.length > 0 &&
                                        _jsx(_Fragment, { children: _jsxs("table", { className: "table table-responsive-sm table-hover table-outline", children: [_jsx("thead", { className: "thead-light", children: _jsxs("tr", { children: [_jsx("th", { scope: "col", children: "Invoice ID" }), _jsx("th", { scope: "col", children: "Amount" }), _jsx("th", { scope: "col", children: "Status" }), _jsx("th", { scope: "col", children: "Invoice Date" }), _jsx("th", { scope: "col", children: "Invoice URL" })] }) }), _jsx("tbody", { children: rows.map(function (r, i) {
                                                            return _jsxs("tr", { children: [_jsx("td", { children: r.id }), _jsxs("td", { children: [currency[r.currency].sign, r.total] }), _jsx("td", { children: r.status.toUpperCase() }), _jsx("td", { children: r.created }), _jsx("td", { children: r.outdated ?
                                                                            _jsx("button", { disabled: invoiceLoading !== '', className: "btn btn-primary", onClick: function () { getOutdatedInvoice(r.id); }, children: invoiceLoading === r.id ? "Getting Invoice.." : "Retrieve Invoice" })
                                                                            : _jsx("a", { href: r.hostedInvoiceUrl, rel: "noreferrer", target: "_blank", className: "btn btn-info", children: "View Invoice" }) })] }, r.id);
                                                        }) })] }) }), loading ? (_jsx(Loader, { text: "Loading data..." })) : (_jsx(_Fragment, { children: toEnd ? (_jsx("span", { children: "End of all invoices" })) : (_jsx("button", { className: "btn btn-primary", onClick: function (e) {
                                                getInvoices(userData.currentAccount.id, pageSize, qs === null || qs === void 0 ? void 0 : qs.docs[qs.docs.length - 1]);
                                            }, children: "View More" })) }))] })] })] }) }) }));
};
export default PaymentList;
